import "./src/css/gsui.styl"; // webpack will compile the stylus to css

// Cookies
function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

docReady(function () {
  if (!readCookie("cconsent")) {
    showCookieBanner();
  }
});

function showCookieBanner() {
  document.getElementById("cookie-banner").style.display = "block";
}

function hideCookieBanner() {
  document.getElementById("cookie-banner").style.display = "none";
}

window.cookiesAccepted = function cookiesAccepted() {
  createCookie("cconsent", true, 365);
  hideCookieBanner();
};
